// 记录加载初始化状态
let isSdkReady = false
 
// 记录加载配置状态
let isConfig = false
 
export default class {
 
  // 初始化
  static init() {
    ysf("onready", function () {
      isSdkReady = true
    });
  }
 
// 配置客户参数，这里业务需要用到，没有业务不需要。
  static Config() {
    ysf("config", {
      uid: parseInt(Math.random() * 100),
      data: JSON.stringify([{
        key: "cs0001",
        value: 'Yt4001081833',
        zone: "true"
      }]),
      success: function () {
        isConfig = true
      }
    });
  }
 
  // 打开客服链接
  static openSdk() {
    // if (isSdkReady&&isConfig) {
    //   // location.href = ysf('url')
    //   ysf('open');
    // } else {
    //   console.log("sdk尚未加载成功，请稍后再试", 'error', 2000);
    // }
    if (isSdkReady) {
      // location.href = ysf('url')
      ysf('open');
    } else {
      console.log("sdk尚未加载成功，请稍后再试", 'error', 2000);
    }
  }
}