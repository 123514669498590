<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
let arrayUrl = [
  '/'
]
export default {
  name: 'App',
  watch: {
    '$route': {
      handler(newVal,oldVal){
        if(arrayUrl.includes(newVal.path)){
          let location = window.location;
          let contentUrl = location.pathname + location.hash;
          let refererUrl = '/';
          window._czc.push(['_trackPageview', contentUrl, refererUrl]); 
        }
      }
    }
  }
}
</script>
<style lang="less">
#app {
  
}
</style>
