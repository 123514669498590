<template>
  <div class="wxb-home">
    <div class="head-area">
      <div class="title-area">
        <img src="../assets/head-logo.png" class="head-logo"/>
        <div class="right-area">
          <!-- <div class="use-area">免费试用</div> -->
          <el-dropdown @command="handleCommand">
            <el-button type="primary" class="use-area">
              免费试用
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="1">维保端</el-dropdown-item>
              <el-dropdown-item command="2">物业端</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <div class="phone-area">
            <img src="../assets/phone.png" class="phone-img">
            <span>400-108-1833</span>
          </div>
        </div>
      </div>
      <el-carousel height="178.66667vw" class="my-carousel">
        <el-carousel-item v-for="(item,index) in imgList" :key="index">
          <img :src="item.url" class="head-bg"/>
        </el-carousel-item>
      </el-carousel>
      <div class="head-content">
        <div class="head-content-first-area">助力维保单位降本增效</div>
        <div class="head-content-second-area">数字化转型</div>
        <!-- <div class="head-content-third-area">免费试用</div> -->
        <el-dropdown @command="handleCommand">
          <el-button type="primary" class="head-content-third-area">
            免费试用
          </el-button>
          <el-dropdown-menu slot="dropdown" class="my-second-dropdown">
            <el-dropdown-item command="1">维保端</el-dropdown-item>
            <el-dropdown-item command="2">物业端</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <div class="number-area">
        <div class="item-number">
          <img src="../assets/user-num.png"/>
          <div class="number-right">
            <div class="value-area">
              <!-- <div class="value">{{ numObj.userCount | getMoneyFormat }}</div> -->
              <div class="value">
                <NumberScroll :start="0" :end="numObj.userCount" :speed="60" :times="100" :format="num => getMoneyFormat(num)"/>
              </div>
              <!-- <div class="unit">个</div> -->
            </div>
            <div class="title">注册用户数（个）</div>
          </div>
        </div>
        <div class="item-number">
          <img src="../assets/lift-num.png"/>
          <div class="number-right">
            <div class="value-area">
              <!-- <div class="value">{{ numObj.liftCount | getMoneyFormat }}</div> -->
              <div class="value">
                <NumberScroll :start="0" :end="numObj.liftCount" :speed="60" :times="100" :format="num => getMoneyFormat(num)"/>
              </div>
              <!-- <div class="unit">台</div> -->
            </div>
            <div class="title">接入电梯数（台）</div>
          </div>
        </div>
        <div class="item-number">
          <img src="../assets/order-num.png"/>
          <div class="number-right">
            <div class="value-area">
              <!-- <div class="value">{{ numObj.workCount | getMoneyFormat }}</div> -->
              <div class="value">
                <NumberScroll :start="0" :end="numObj.workCount" :speed="60" :times="100" :format="num => getMoneyFormat(num)"/>
              </div>
              <!-- <div class="unit">条</div> -->
            </div>
            <div class="title">提交维保工单数（条）</div>
          </div>
        </div>
        <div class="item-number">
          <img src="../assets/customer-num.png"/>
          <div class="number-right">
            <div class="value-area">
              <!-- <div class="value">{{ numObj.unitCount | getMoneyFormat }}</div> -->
              <div class="value">
                <NumberScroll :start="0" :end="numObj.unitCount" :speed="60" :times="100" :format="num => getMoneyFormat(num)"/>
              </div>
              <!-- <div class="unit">家</div> -->
            </div>
            <div class="title">服务企业客户（家）</div>
          </div>
        </div>
      </div>
    </div>
    <div class="smart-lift">
      <div class="text-area">
        <div class="title">什么是智慧电梯？</div>
        <div class="content">不改变电梯基本结构的情况下，通过配置智能终端设备如网关、传感器等，并利用物联网、大数据、云计算等技术，采用和传输电梯各类信息，后台通过大数据分析和与外部系统综合决策，实现电梯的实时监测、事故自动报警、应急救援、无纸化维保等智能化管理。</div>
      </div>
      <img src="../assets/what-smart-lift.png" class="what-smart-lift"/>
      <div class="wxb-lift-area">
        <div class="title">
          <span>云梯维小保</span>
          <span class="title-lift">智慧电梯</span>
        </div>
        <div class="content">云梯维小保通过电梯云盒的传感器采集电梯运行数据，实时监测电梯状态及事件/故障，上报至智慧电梯云管理平台进行监控管理，为客户提供电梯运行诊断数据，便于电梯维护判断。</div>
      </div>
      <img src="../assets/wxb-lift.png" class="wxb-lift"/>
      <div class="smart-lift-bottom-area">
        <div class="item-area">
          <img src="../assets/lift-cl.png" class="lift-cl-img"/>
          <div class="right-area">
            <div class="title">非接触式测量</div>
            <div class="name">对电梯的动力系统无影响</div>
          </div>
        </div>
        <div class="line"></div>
        <div class="item-area">
          <img src="../assets/lift-js.png" class="lift-js-img"/>
          <div class="right-area">
            <div class="title">无线网桥技术</div>
            <div class="name">最合适的井道内信号传输技术</div>
          </div>
        </div>
        <div class="line"></div>
        <div class="item-area xx-area">
          <img src="../assets/lift-xx.png" class="lift-xx-img"/>
          <div class="right-area">
            <div class="title">电梯场景分析学习</div>
            <div class="name">关注点在电梯场景，对场景内的数 据如：电梯运行数据、环境数据、乘梯人数据分析学习</div>
          </div>
        </div>
      </div>
    </div>
    <div class="do-what">
      <div class="title-area">我们能做什么？</div>
      <div class="icon-area">
        <div class="item-icon">
          <img src="../assets/software-service.png"/>
          <div class="title">软件服务</div>
          <div class="name">无纸化维保管理平台</div>
        </div>
        <div class="item-icon">
          <img src="../assets/hardware-service.png"/>
          <div class="title">硬件服务</div>
          <div class="name">业内领先的智能硬件设备</div>
        </div>
        <div class="item-icon">
          <img src="../assets/add-service.png"/>
          <div class="title">增值服务</div>
          <div class="name">支持定制化开发</div>
        </div>
      </div>
    </div>
    <div class="core-function">
      <div class="title">核心功能</div>
      <div class="my-tab">
        <img src="../assets/tab-sj.png" class="sj-img" :style="{left: (165+(Number(activeName)-1)*361 + 'px')}"/>
        <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
          <el-tab-pane label="智慧维保" name="1">
            <img src="../assets/tab-zhwb.png" class="tab-zhwb"/>
          </el-tab-pane>
          <el-tab-pane label="应急救援" name="2">
            <img src="../assets/tab-yjjy.png" class="tab-yjjy"/>
          </el-tab-pane>
          <el-tab-pane label="智能办公" name="3">
            <img src="../assets/tab-znbg.png" class="tab-znbg"/>
          </el-tab-pane>
          <el-tab-pane label="智能预警" name="4">
            <img src="../assets/tab-znyj.png" class="tab-znyj"/>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <div class="customer-area">
      <div class="title">品牌客户</div>
      <img src="../assets/brand-customer.png" class="brand-customer"/>
    </div>
    <div class="apply-area">
      <div class="title">免费申请云梯</div>
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="demo-ruleForm">
        <el-form-item label="姓名" prop="userName">
          <el-input v-model="ruleForm.userName"></el-input>
        </el-form-item>
        <el-form-item label="电话" prop="mobilePhone">
          <el-input v-model="ruleForm.mobilePhone"></el-input>
        </el-form-item>
        <el-form-item label="公司" prop="plotName" class="company">
          <el-input v-model="ruleForm.plotName"></el-input>
        </el-form-item>
      </el-form>
      <div class="btn" @click="commitClick">免费申请</div>
    </div>
    <div class="consult-area">
      <div class="title">马上开启数字化电梯之旅</div>
      <div class="content">期待与您的合作</div>
      <div class="btn-area">
        <!-- <div class="ty">免费体验</div> -->
        <el-dropdown @command="handleCommand">
          <el-button type="primary" class="ty">
            免费体验
          </el-button>
          <el-dropdown-menu slot="dropdown" class="my-dropdown">
            <el-dropdown-item command="1">维保端</el-dropdown-item>
            <el-dropdown-item command="2">物业端</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <div class="consult"  @click="linkKefu">立即咨询</div>
      </div>
    </div>
    <div class="bottom-area">
      <div class="first-area">
        <div class="left-area">
          <div class="title-area">
            <img src="../assets/bottom-logo.png" class="bottom-logo"/>
            <div class="title">让&nbsp;&nbsp;&nbsp;电&nbsp;&nbsp;&nbsp;梯&nbsp;&nbsp;&nbsp;维&nbsp;&nbsp;&nbsp;保&nbsp;&nbsp;&nbsp;无&nbsp;&nbsp;&nbsp;纸&nbsp;&nbsp;&nbsp;化&nbsp;&nbsp;&nbsp;更&nbsp;&nbsp;&nbsp;轻&nbsp;&nbsp;&nbsp;松</div>
          </div>
          <div class="scan-area">
            <div class="wx-scan">
              <div class="name">微信公众号</div>
              <div class="scan"><img src="../assets/wx-scan.png" class="scan-img"/></div>
            </div>
            <div class="wy-app">
              <div class="name">APP下载</div>
              <div class="scan">
                <img src="../assets/wyDownload.png" class="scan-img"/>
                <div class="detail">前往应用市场搜索“维小保-物业” 或扫描左侧二维码下载安装</div>
              </div>
            </div>
            <div class="wb-app">
              <div class="scan">
                <img src="../assets/wbDownload.png" class="scan-img"/>
                <div class="detail">前往应用市场搜索“维小保-维保” 或扫描左侧二维码下载安装</div>
              </div>
            </div>
          </div>
        </div>
        <div class="right-area">
          <div class="title">联系我们</div>
          <div class="content">浙江新再灵科技股份有限公司</div>
          <div class="content">地址：浙江省杭州市滨安路597号恒鼎大厦7楼</div>
          <div class="content">电话：400-108-1833</div>
          <div class="content last-content">邮箱：xinzailing@zailingtech.com</div>
        </div>
      </div>
      <div class="line"></div>
      <div class="second-area">© 2016 浙江新再灵科技股份有限公司 All rights reserved / <a target="_blank" href="http://beian.miit.gov.cn" style="color: #fff">浙ICP备16006581号-5</a> / <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33010802009289" style="color: #fff">浙公安网络备案号33010802009289</a></div>
    </div>
    <div class="fixing-strip">
      <div class="percent-50">
        <el-popover
          popper-class="my-popover"
          placement="left"
          trigger="hover">
          <img src="../assets/wbDownload.png" class="scan-img"/>
          <div class="wb-area" slot="reference">维保端</div>
        </el-popover>
        <div class="fixing-strip-line"></div>
        <el-popover
          popper-class="my-popover"
          placement="left"
          trigger="hover">
          <img src="../assets/wyDownload.png" class="scan-img"/>
          <div class="wy-area" slot="reference">物业端</div>
        </el-popover>
      </div>
      <div class="percent-50 second-percent-50">
        <div class="lxwm-area" @click="linkKefu">
          <img src="../assets/customer-service.png" class="customer-service"/>
          <div class="lxwm-name">联系我们</div>
        </div>
        <div class="arrow" @click="toTop"><img src="../assets/top-arrow.png" class="top-arrow"/></div>
      </div>
    </div>
  </div>
</template>

<script>
import {  apply, getData } from '../api/index' 
import { formatMoney } from '../utils/utils.js'
import NumberScroll from 'vue-number-scroll'
export default {
  components: {
    NumberScroll
  },
  data(){
    return {
      activeName: '1',
      ruleForm: {
        userName: '',
        mobilePhone: '',
        plotName: ''
      },
      rules: {
        userName: [
          { required: true, message: '请输入姓名', trigger: 'blur' },
        ],
        mobilePhone: [
          { required: true, message: '请输入电话', trigger: 'blur' },
          { validator: this.validatePhone, trigger: 'blur' }
        ],
        plotName: [
          { required: true, message: '请输入公司', trigger: 'blur' },
        ],
      },
      time: false,
      imgList: [
        {
          url: require('../assets/head-bg.png')
        }
      ],
      numObj:{
        userCount: 0,
        liftCount: 0,
        workCount: 0,
        unitCount: 0,
      }
    }
  },
  methods: {
    getMoneyFormat(val){
      // val = 9999999
      return formatMoney(val)
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },
    toTop(){
      document.documentElement.scrollTop = 0;
    },
    commitClick(){
      if(this.time){
        this.$message.warning('操作过于频繁，请3秒后重试！');
        return
      }
      this.$refs['ruleForm'].validate(async(valid) => {
        if (valid) {
          let params = {
            ...this.ruleForm
          }
          let res = await apply(params)
          console.log('res',res)
          this.time = true
          setTimeout(() => {
            this.time = false
          }, 3000)
          if(res.code===200){
            this.$message({
              message: '提交成功！我们将尽快安排工作人员与您联系。',
              type: 'success'
            });
          }else {
            this.$message.error('服务器请求异常，请稍候再试。');
          }
        }
      });
    },
    validatePhone(rule, value, callback){
      let reg = /^(?:(?:\+|00)86)?1[3-9]\d{9}$/
      if (value === '') {
        callback(new Error('请输入电话'));
      } else if (!reg.test(value)) {
        callback(new Error('请输入正确的电话格式'));
      } else {
        callback();
      }
    },
    handleCommand(command){
      console.log('command',command)
      let location = window.location.host
      if(command=='1'){
        if(location.indexOf("gwcs")>-1||location.indexOf("localhost")>-1){
          window.open('https://wbcs.wxbtech.com/')
        }else{
          window.open('https://wb.wxbtech.com/')
        }
      }else{
        if(location.indexOf("gwcs")>-1||location.indexOf("localhost")>-1){
          window.open('https://wycs.wxbtech.com/')
        }else{
          window.open('https://wy.wxbtech.com/')
        }
      }
    },
    linkKefu(){
      this.$fishkefu.openSdk();
    },
    async getData(){
      let res = await getData({})
      console.log('res',res)
      this.numObj = res.data
    }
  },
  created(){
    setTimeout(()=>{
      this.getData()
    },1000)
  },
  mounted(){
    this.$fishkefu.init();
    this.timer = setInterval(()=>this.getData(), 10000);
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
}
</script>

<style lang="less" scoped>
.wxb-home {
  width: 100%;
  height: 100%;
  overflow-x:hidden;
  .head-area {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 670px;
    .title-area {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 0;
      width: 100%;
      height: 67px;
      background: rgba(255,255,255,0.7);
      z-index: 100;
      .right-area {
        display: flex;
        align-items: center;
        color: #222222;
        .use-area {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 113px;
          height: 41px;
          background: #377CF7;
          border-radius: 4px;
          font-weight: 400;
          color: #FFFFFF;
          text-align: center;
          line-height: 41px;
        }
        .use-area:hover {
          cursor: pointer;
        }
        .phone-area {
          display: flex;
          align-items: center;
          margin-left: 51px;
          .phone-img {
            width: 17px;
            height: 17px;
            margin-right: 5px;
          }
          span {
            font-size: 12px;
          }
        }
      }
      .head-logo {
        width: 210px;
        height: 31px;
        margin-right: 945px;
      }
    }
    .head-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      position: absolute;
      top: 230px;
      left: calc( 50% - 721px);
      width: 1442px;
      z-index: 100;
      .head-content-first-area,
      .head-content-second-area {
        font-size: 48px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #222222;
      }
      .head-content-third-area {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 237px;
        height: 41px;
        background: #377CF7;
        box-shadow: 0px 5px 12px 0px rgba(72,138,255,0.41);
        border-radius: 4px;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
        margin-top: 31px;
      }
    }
    .number-area {
      display: flex;
      align-items: center;
      justify-content: space-around;
      position: absolute;
      bottom: -99px;
      left: calc( 50% - 721px);
      width: 1442px;
      height: 216px;
      background: #F9FCFF;
      box-shadow: 0px 0px 38px 0px rgba(182,203,224,0.53);
      border-radius: 4px;
      border: 2px solid #FFFFFF;
      z-index: 100;
      .item-number {
        display: flex;
        img {
          width: 93px;
          height: 93px;
        }
        .number-right {
          display: flex;
          flex-direction: column;
          // align-items: center;
          position: relative;
          top: -12px;
          .value-area {
            display: flex;
            position: relative;
            width: 245px;
            .value {
              font-size: 50px;
              font-family: DINAlternate-Bold, DINAlternate;
              font-weight: bold;
              color: #282D3B;
            }
            .unit {
              position: absolute;
              right: -20px;
              bottom: 10px;
              font-size: 18px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              color: #222222;
            }
          }
          .title {
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #666666;
          }
        }
      }
    }
   
  }
  .smart-lift {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height: 2173px;
    background-image: url(../assets/smart-lift-bg.png);
    background-repeat: no-repeat;
    background-size: 100% 2173px;
    text-align: center;
    .text-area {
      width: 1394px;
      padding-top: 107px;
      text-align: center;
      margin-bottom: 24px;
      .title {
        margin-bottom: 20px;
        font-size: 48px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #222222;
      }
      .content {
        font-size: 22px;
        font-family: PingFang-SC-Regular, PingFang-SC;
        font-weight: 400;
        color: #222222;
        line-height: 1.8;
      }
    }

    .wxb-lift-area {
      width: 1285px;
      margin-top: 107px;
      text-align: center;
      margin-left: 30px;
      margin-bottom: 49px;
      .title {
        font-size: 48px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #2872FB;
        margin-bottom: 20px;
        .title-lift {
          color: #222222
        }
      }
      .content {
        font-size: 22px;
        font-family: PingFang-SC-Regular, PingFang-SC;
        font-weight: 400;
        color: #222222;
        line-height: 1.8;
      }
    }

    .smart-lift-bottom-area {
      display: flex;
      justify-content: space-around;
      margin-top: 88px;
      width: 1426px;
      .item-area {
        display: flex;
        text-align: left;
        .lift-cl-img {
          width: 64px;
          height: 64px;
        }
        .lift-js-img {
          width: 64px;
          height: 59px;
        }
        .lift-xx-img {
          width: 59px;
          height: 60px;
        }
        .right-area {
          display: flex;
          flex-direction: column;
          margin-left: 19px;
          .title {
            font-size: 26px;
            font-family: PingFangSC;
            color: #222222;
            line-height: 1;
            margin-bottom: 5px;
          }
          .name {
            font-size: 20px;
            font-family: PingFangSC-Light, PingFang SC;
            font-weight: 300;
            color: #333333;
          }
        }
      }
      .xx-area {
        width: 379px;
      }
      .line {
        width: 0px;
        height: 86px;
        border: 1px solid #BED9FF;
      }
    }
    .what-smart-lift {
      width: 1041px;
      height: 556px;
    }
    .wxb-lift {
      width: 1426px;
      height: 643px;
    }
  }
  .do-what {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    margin-top: 148px;
    width: 1371px;
    .title-area {
      font-size: 48px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #222222;
      margin-bottom: 52px;
    }
    .icon-area {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      .item-icon {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .title {
          font-size: 36px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #222222;
          margin-bottom: 11px;
        }
        .name {
          font-size: 24px;
          font-family: PingFangSC-Light, PingFang SC;
          font-weight: 300;
          color: #888888;
        }
        img {
          width: 222px;
          height: 186px;
        }
      }
      
    }
  }
  .core-function {
    text-align: center;
    width: 1439px;
    margin: 0 auto;
    margin-top: 169px;
    .title {
      font-size: 48px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #222222;
      margin-bottom: 46px;
    }
    .my-tab {
      position: relative;
      ::v-deep .el-tabs{
        .el-tabs__header {
          margin: 0;
          .el-tabs__nav {
            height: 77px;
          }
          .el-tabs__nav-wrap {
            padding: 0;
            .el-tabs__nav-prev,
            .el-tabs__nav-next {
              display: none;
            }
            .el-tabs__nav {
              float: unset;
            }
            .is-active {
              position: relative;
              background: #377CF7;
              box-shadow: 4px -3px 20px 0px rgba(101,151,203,0.46);
              font-size: 30px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              color: #FFFFFF;
            }
          }
        }
        .el-tabs__item {
          width: 361px;
          height: 77px;
          line-height: 77px;
          font-size: 24px;
          font-family: PingFang-SC-Regular, PingFang-SC;
          font-weight: 400;
          color: #888888;
        }
        .el-tabs__content {
          height: 622px;
          background-image: url(../assets/tab-content-bg.png);
          background-repeat: no-repeat;
          background-size: 100% 100%;
          .el-tab-pane {
            img {
              margin-top: 56px;
              margin-left: 80px;
            }
            .tab-zhwb {
              width: 1240px;
              height: 507px;
            }
            .tab-yjjy {
              width: 1150px;
              height: 480px;
            }
            .tab-znbg {
              width: 1332px;
              height: 481px;
            }
            .tab-znyj {
              width: 1305px;
              height: 461px;
            }
          }
        }
      }
      .sj-img {
        position: absolute;
        top: 77px;
        left: 165px;
        z-index: 10;
      }
    }
  }
  .customer-area {
    margin-top: 149px;
    text-align: center;
    .title {
      font-size: 48px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #222222;
      margin-bottom: 76px;
    }
    .brand-customer {
      width: 1249px;
      height: 297px;
    }
  }
  .apply-area {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 168px;
    text-align: center;
    .title {
      font-size: 48px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #377CF7;
      margin-bottom: 49px;
    }
    .demo-ruleForm {
      display: flex;
      flex-wrap: wrap;
      margin: 0 583px;
      width: 800px;
      ::v-deep .el-form-item {
        display: flex;
      }
      ::v-deep .el-form-item__label {
        width: 100px;
      }
      ::v-deep .el-form-item__content {
        width: 300px;
      }
      .company {
        ::v-deep .el-form-item__content {
          width: 700px;
        }
      }
    }
    .btn {
      width: 237px;
      height: 41px;
      line-height: 41px;
      background: #377CF7;
      box-shadow: 0px 5px 12px 0px rgba(72,138,255,0.41);
      border-radius: 4px;
      text-align: center;
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #FFFFFF;
      margin-top: 31px;
      margin-bottom: 102px;
    }
    .btn:hover {
      cursor: pointer;
    }
  }

  .consult-area {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 302px;
    background-image: url(../assets/bottom-bg.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    .title {
      font-size: 38px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #FFFFFF;
      margin-bottom: 9px;
    }
    .content {
      font-size: 21px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #D1F0FF;
      margin-bottom: 34px;
    }
    .btn-area {
      display: flex;
      .ty {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 222px;
        height: 59px;
        border-radius: 4px;
        margin-right: 36px;
        text-align: center;
        line-height: 59px;
        background: #FFFFFF;
        font-size: 26px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #377CF7;
      }
      .ty:hover,
      .consult:hover{
        cursor: pointer;
      }
      .consult {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 222px;
        height: 54px;
        margin-right: 36px;
        text-align: center;
        height: 54px;
        box-shadow: inset 0px 0px 4px 2px rgba(255,255,255,0.45);
        border-radius: 4px;
        border: 2px solid #FFFFFF;
        font-size: 26px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
      } 
    }
  }
  .bottom-area {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 518px;
    background: #2E364D;
    .first-area {
      display: flex;
      padding-top: 39px;
      .left-area {
        .title-area {
          display: flex;
          align-items: center;
          margin-top: 48px;
          margin-bottom: 37px;
          img {
            width: 243px;
            height: 36px;
            margin-right: 18px;
            margin-left: 234px;
          }
          .title {
            padding-left: 20px;
            border-left: 2px solid #FFFFFF;
            font-size: 26px;
            font-family: PingFang-SC-Regular, PingFang-SC;
            font-weight: 400;
            color: #FFFFFF;
          }
        }
        .scan-area {
          display: flex;
          margin-left: 234px;
          .wx-scan {
            margin-right: 40px;
            .scan {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 115px;
              height: 114px;
              border: 2px solid #555B6D;
            }
          }
          .wy-app,
          .wb-app {
            margin-right: 9px;
            .scan {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 383px;
              height: 114px;
              border: 2px solid #555B6D;
              .detail {
                width: 263px;
                font-size: 17px;
                font-family: PingFang-SC-Regular, PingFang-SC;
                font-weight: 400;
                color: #A3B1DF;
                margin-left: 14px;
              }
            }
          }
          .wb-app {
            margin-top: 29px;
          }
          
        }
        .name {
          font-size: 18px;
          font-family: PingFang-SC-Regular, PingFang-SC;
          font-weight: 400;
          color: #A3B1DF;
          margin-bottom: 5px;
        }
      }

      .right-area {
        padding-top: 59px;
        padding-left: 52px;
        border-left: 1px solid #555B6D;
        .title {
          font-size: 20px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #D1DCFD;
          margin-bottom: 20px;
        }
        .content {
          font-size: 18px;
          font-family: PingFang-SC-Regular, PingFang-SC;
          font-weight: 400;
          color: #D1DCFD;
          margin-bottom: 25px;
        }
        .last-content {
          margin-bottom: 27px;
        }
      }
    }
    .line {
      width: 1500px;
      border: 1px solid #555B6D;
      margin-left: 210px;
    }
    .second-area {
      margin-top: 75px;
      text-align: center;
      font-size: 18px;
      font-family: PingFang-SC-Regular, PingFang-SC;
      font-weight: 400;
      color: #FFFFFF;
    }
  }

  .fixing-strip {
    position: fixed;
    top: 500px;
    right: 34px;
    width: 78px;
    height: 315px;
    background: #F9FCFF;
    box-shadow: 0px 0px 20px 0px rgba(182,203,224,0.5);
    border-radius: 39px;
    z-index: 10;
    .percent-50 {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 50%;
      background: #377CF7;
      border-radius: 39px 39px 0px 0px;
      .wb-area,
      .wy-area {
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
      }
      .wb-area:hover,
      .wy-area:hover{
        cursor: pointer;
      }
      .fixing-strip-line {
        width: 66px;
        border: 1px solid #9DC0FF;
        margin: 23px 0;
      }
    }
    .second-percent-50 {
      background: #FFFFFF;
      border-radius: 0 39px 39px 39px;
      .lxwm-area {
        text-align: center;
      }
      .lxwm-area:hover {
        cursor: pointer;
      }
      .customer-service {
        width: 30px;
        height: 30px;
      }
      .lxwm-name {
        margin-top: 6px;
        margin-bottom: 17px;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #2389E8;
      }
      .arrow {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 37px;
        height: 37px;
        .top-arrow {
          width: 21px;
          height: 12px;
        }
      }
      .arrow:hover {
        cursor: pointer;
      }
    }
  }
  .scan-img {
    width: 101px;
    height: 101px;
  }
  .my-carousel {
    overflow-y: hidden;
  }
  .head-bg {
    width: 100%;
    height: 670px;
  }
  .bottom-logo {
    width: 243px;
    height: 36px;
  }
}
</style>

<style lang="less">
.my-popover {
  width: 100px !important;
  background: #c0c4cc82 !important;
  min-width: unset !important;
  .scan-img {
    width: 101px;
    height: 101px;
  }
}
.el-dropdown-menu {
  width: 113px;
  left: 1398px;
  .popper__arrow {
    left: 50px;
  }
}
.my-dropdown {
  width: 220px;
  left: 692px !important;
  .popper__arrow {
    left: 103px !important;
  }
}

.my-second-dropdown {
  width: 237px;
  left: calc( 50% - 721px) !important;
  .popper__arrow {
    left: 116px !important;
  }
}
</style>

