import axios from 'axios'

const Axios = axios.create({
  baseURL: '/WXB',
  timeout: 10000,
  responseType: 'json',
  withCredentials: true
})

// 返回状态判断(添加响应拦截器)
Axios.interceptors.response.use(
  res => {
    if (res.data.code === 200) {
      return Promise.resolve(res.data)
    } else {
      return Promise.reject(res.data)
    }
  },
  error => {
    return Promise.reject(error)
  }
)

export default Axios
