import resquest from './axios'

function apply(params){
    let url = 'ant-server/apply/record/v1'
    return resquest.post(url,params)
}

function getData(params){
    let url = 'newton-server/noAuth/home/data'
    return resquest.get(url,{params})
}

export {
    apply,
    getData
}